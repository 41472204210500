import logo from './logo.svg';
import { useEffect, useState } from 'react';
import './App.css';
import OktaLogin from './components/OktaLogin';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import AddCheckOutButton from './components/CheckOutButton';
import AddCheckInButton from './components/CheckInButton';
import iPhone from './devices/iPhone.png';
import DexcomG6Tx from './devices/Dexcom G6 Tx.png';
import Android from './devices/Android.png';
import X2ClosedPump from './devices/X2 Closed Pump.png';
import X2OpenPump from './devices/X2 Open Pump.png';
import mysterybox from './devices/mysterybox.webp';
import G6WalkaboutBox from './devices/G6 Walkabout Box.png'
import JTAG from './devices/JTAG.png'
import SmallerJTAG from './devices/SmallerJTAG.png';
import BiggerJTAG from './devices/BiggerJTAG.png';
import TxEmulator from './devices/Tx Emulator.png';


function App() {
  const [db, setdb] = useState();
  const [app, setapp] = useState();
  const [user, setuser] = useState();
  const [userEmail, setuserEmail] = useState("");
  const [Device, setDevice] = useState(false);
  const [deviceOwner, setdeviceOwner] = useState();
  const [image, setImage] = useState("");


  const url = new URL(
    window.location.href
      //'https://inventrak.tandemdiabetes.com/?serialNumber=123&equipNumber=456&item=Pump'
    );



  let item = url.searchParams.get('item'); 
  let serialNumber = url.searchParams.get('serialNumber');
  let equipNumber = url.searchParams.get('equipNumber');
  const docID = (item + " " + serialNumber);

  
  const buttonProps= {item, serialNumber, equipNumber, db}
 


    useEffect(() => {
    switch(item) {
      case 'Android':
        setImage(Android);
        break;
      case "iPhone" :
          setImage(iPhone);
          break;
      case 'Dexcom G6 Tx':
        setImage(DexcomG6Tx);
        break;
      case 'X2 Closed Pump':
        setImage(X2ClosedPump);
        break;
      case 'JTAG':
        setImage(JTAG);
        break;
      case 'X2 Open Pump':
        setImage(X2OpenPump);
        break;
      case 'G6 Walkabout Box':
        setImage(G6WalkaboutBox);
        break;
      case 'Smaller JTAG':
        setImage(SmallerJTAG)
      case 'Bigger JTAG':
        setImage(BiggerJTAG);
        break;
      case 'Tx Emulator':
        setImage(TxEmulator);
        break;
      default:
        setImage(mysterybox);
    }
      
const firebaseConfig = {
  apiKey: "AIzaSyDfkQVuSt7aVFueLNhWZf3Lfmel0VbgKDY",
  authDomain: "ecco-development.firebaseapp.com",
  databaseURL: "https://ecco-development-default-rtdb.firebaseio.com",
  projectId: "ecco-development",
  storageBucket: "ecco-development.appspot.com",
  messagingSenderId: "220078443329",
  appId: "1:220078443329:web:e5ccfee2568b9d4c2b6fc0",
  measurementId: "G-ZWW2Z64EV5"
};

// Initialize Firebase
setapp(initializeApp(firebaseConfig));


// Initialize Cloud Firestore and get a reference to the service
setdb(getFirestore(app));

console.log("hi" + Device)




})

  return (
    <div className="App"> 
    {!user && app ? <OktaLogin setuser= {setuser} setuserEmail = {setuserEmail}  db={db} docID={docID} setDevice={setDevice} setdeviceOwner={setdeviceOwner}/> :
    <>
      <header className="App-header"> 
        <img src={logo} className="App-logo" alt="logo" /> 
      </header>
      
      <article className="main-content">
        <h1 className="main-title"> 
          Hello, <span className="username">{userEmail.split("@")[0]}</span>!
        </h1> 
        
        <div className='item-image-wrapper'>
        <div className="glow"></div>
        <div className="glow glow-alternate"></div>
          {image && <img src={image} className="item-image" alt="Scanned item" />}
        </div>

        <div className="info-box">
          
            <div className='item-name'>
              {item}
            </div> 

          <div className="item-info">
            Equipment number: {equipNumber}
          </div>

          <div className='item-info'>
            Serial number: {serialNumber}
          </div>
        </div>
        

        <div className='item-info'>
        <span className={`${deviceOwner ? 'tag-checked-out' : 'tag-available'} tag`}>
          {deviceOwner ? `Checked out by ${deviceOwner}` : "Available"}
        </span>
      </div>
      
        { Device ?
            <AddCheckOutButton {...buttonProps} userEmail={userEmail} setdeviceOwner={setdeviceOwner} docID={docID} />
          : <AddCheckInButton {...buttonProps} userEmail={userEmail} setdeviceOwner={setdeviceOwner} docID={docID} deviceOwner={deviceOwner} />
        }
      </article>
    </>}
    </div>
  );
}



export default App;