import { setDoc, doc, updateDoc, arrayUnion, getDoc} from "firebase/firestore";
import { useState } from "react";
import './button.css';



function AddCheckOutButton({db, item, serialNumber, equipNumber, userEmail, setdeviceOwner, docID}) {
  const initialText= "Check Out";
  const [checkOutbutton, setcheckOutbutton] = useState(initialText);
  const [disable, setdisable] = useState(false)
  const userName = userEmail.split("@")[0]
  const current = new Date();
  const date = `${current.getMonth()+1}-${current.getDate()}-${current.getFullYear()}`;
  
const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

  let docRef;
  let docSnap;

async function checkOut() {

    docRef = doc(db, "devices", (docID));
    docSnap = await getDoc(docRef) 

  if (docSnap.exists() && docSnap.data().Available===true) {
     docRef = doc(db, "users", (userName));
     docSnap = await getDoc(docRef) 

     
  if (docSnap.exists()) {
  console.log("Document data:", docSnap.data());
  } 
  else {
  await setDoc(docRef, {
    name: (userName),
    email: (userEmail),
  })
  }


    docRef = doc(db, "users", (userName));

  await updateDoc(docRef, {
    "devices": arrayUnion(item + " " + serialNumber),
  });

    docRef= doc(db, "devices", (docID), "history", (date));
  await setDoc(docRef, {
    Users: arrayUnion(userName)
  })



    docRef = doc(db, "devices", (item + " " + serialNumber));
  await setDoc(docRef, {
    CheckedOut: (userName),
    Available: false,
    serialNumber: (serialNumber),
    equipmentNumber: (equipNumber)
  })

setcheckOutbutton("Loading...")

await delay(1000);

setcheckOutbutton("Checked Out")
setdeviceOwner(userName)
}

  else{
  setdeviceOwner(docSnap.data().CheckedOut)
  setcheckOutbutton("Error")
  }



setdisable(true)
   }

  return(
    <>

        <button onClick={(checkOut)} className={`buttonout ${disable ? 'action-complete' : ''}`} disabled={disable ? true : false}>
            {checkOutbutton}
        </button>
        

    </>
  )
}




export default AddCheckOutButton;