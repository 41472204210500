import OktaSignIn from '@okta/okta-signin-widget';
import { OKTA_ORG_URL , CUSTOM_TOKEN_ENDPOINT , OKTA_CLIENT_ID } from './okta-config';
import { getAuth, signInWithCustomToken } from 'firebase/auth'; 
import { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, where, query, getDocs, doc, getDoc } from "firebase/firestore";

function OktaLogin({setuser, setuserEmail, db, docID, setDevice, setdeviceOwner}) {

  useEffect(() => {


    const asyncFunction = async () => {
     const oktaSignIn = new OktaSignIn({
       baseUrl: OKTA_ORG_URL,
       redirectUri: window.location.origin,
       clientId: OKTA_CLIENT_ID,
       logo: '/oktaphoto.jpg',
       authParams: { 
         display: 'page'
       },
       el: "#signin-widget"
      });

     


     oktaSignIn.showSignIn({
      // Assumes there is an empty element on the page with an id of ‘osw-container’
      el: "#signin-widget",
    }).then(async res => {
      //oktaSignIn.authClient.handleLoginRedirect(res.tokens);
      console.log(res.tokens.accessToken.claims.sub);
    
      setuserEmail(res.tokens.accessToken.claims.sub)
      const accessToken = res.tokens.accessToken.accessToken;

       // Use the access token to call the firebaseCustomToken endpoint.
       const firebaseTokenResponse = await fetch(CUSTOM_TOKEN_ENDPOINT, {
         headers: {
           'Authorization': `Bearer ${accessToken}`
         }
       });
       const firebaseToken = await firebaseTokenResponse.text();
//console.log(firebaseToken);

       // (Continued below.)
       try {
        const auth= getAuth();
        const userCred = await signInWithCustomToken(auth, firebaseToken);
        console.log(userCred);
         console.log("yay");

         

          const docRef = doc(db, "devices", (docID));
          let docSnap = await getDoc(docRef) 
     if (docSnap.exists() && docSnap.data().Available===true) {
      setDevice(true)
     } else {
      setdeviceOwner(docSnap?.data()?.CheckedOut)
     }
     
        
          
        
        
         setuser(userCred.user);
        

       } catch (err) {
        console.log(err);
         console.error('Error signing in with custom token.');
       }
    }).catch(function(error) {
      // This function is invoked with errors the widget cannot recover from:
      // Known errors: CONFIG_ERROR, UNSUPPORTED_BROWSER_ERROR
      console.log('login error', error);
    });
   }
  
   asyncFunction();
   

})


  return (
    <>
    <div id= "signin-widget">

    </div>
    </>
  );
}

export default OktaLogin;
