//
// Configuration for Okta/Firebase demo client (index.html)
//

// Okta org URL, from the Dashboard page of the Okta developer console.
const OKTA_ORG_URL = "https://tandem.okta.com";

// Okta client ID, from the Application page of the Okta developer console.
const OKTA_CLIENT_ID = "0oa1ozjmfhdzF0HpH0h8";

// The complete URL of your custom token endpoint. When using the emulator, the
// endpoint is:
// http://localhost:5001/<YOUR_FIREBASE_PROJECT_ID>/us-central1/api/firebaseCustomToken
const CUSTOM_TOKEN_ENDPOINT =
  "https://us-central1-ecco-development.cloudfunctions.net/api/firebaseCustomToken";

if (
  OKTA_ORG_URL === "" ||
  OKTA_CLIENT_ID === "" ||
  CUSTOM_TOKEN_ENDPOINT === ""
) {
  console.error("Okta configuration not set in okta-config.js.");
}

export {OKTA_CLIENT_ID, OKTA_ORG_URL, CUSTOM_TOKEN_ENDPOINT};
