import { doc, setDoc, updateDoc, arrayRemove, getDoc } from "firebase/firestore";
import { useState } from "react";
import './button.css';


function AddCheckInButton({db, item, serialNumber, equipNumber, userEmail, setdeviceOwner, docID, deviceOwner}) {
    const initialText= "Check In";
  const [checkInbutton, setcheckInbutton] = useState(initialText);
  const [disable, setdisable] = useState(false)


  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  
    let docRef;
    let docSnap;

async function checkIn() {

    docRef = doc(db, "devices", (docID) );
    console.log("hi5")
  await setDoc(docRef, {
    Available: true,
    checkedOut: "NA",
    serialNumber: (serialNumber),
    equipmentNumber: (equipNumber)
  });

if(deviceOwner){
    docRef = doc(db, 'users', (deviceOwner));
  await updateDoc(docRef, {
    "devices": arrayRemove(item + " " + serialNumber)
  });
}
  
setdisable(true)
setdeviceOwner(false)
}

async function handleClick() {
  setcheckInbutton("Loading...")

  await delay(100);

   docRef = doc(db, "devices", (docID));
    docSnap = await getDoc(docRef) 

  if (docSnap.exists() && docSnap.data().Available===true) {
      setcheckInbutton("Checked In")
     } 
    
  else {
    setcheckInbutton("Error")
     }
    }

  



 
  return(
    <div >
        <button onClick={() => { checkIn();handleClick()}} className={`buttonin ${disable ? 'action-complete' : ''}`} disabled={disable ? true : false}>
            {checkInbutton}
        </button>

        

    </div>
  )
}




export default AddCheckInButton;